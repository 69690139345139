import React from 'react'
import RTE from 'react-typing-effect'

import Data from '../../assets/Achievements.json'

class AboutMe extends React.Component {
  getEducation(json) {
    var set = []
    for (var i = 0; i < json.length; i++) {
      const d = json[i]
      set.push(
        <div key={i} className='margin'>
          <div className='teamEx'>
            <p><strong>{d['name']}</strong></p>
            <p>{d['time']}</p>
          </div>
          <div className='teamEx'>
            <p>{d['loc']}</p>
            <p>{d['grade']}</p>
          </div>
          <p><i>{d['gpa']}</i></p>
          <div className='des' />
        </div>
      )
    }
    return set
  }

  render() {
    return (
      <>
        <h1 className='textCenter'>
          Hello,
          <br />
          {'I am a '}
          <strong>
            <RTE
              speed='80'
              eraseDelay='1200'
              typingDelay='80'
              text={[
                'HyunJun Park.',
                'student.',
                'software engineer.',
                'designer.',
              ]}
            />
          </strong>
        </h1>
        <div className='pageBody'>
          <p>
            Science has forever captivated me. From the simple lines of code running through a mobile app to the complex neural networks behind autonomous driving systems, code is what makes all this possible. Behind every technological wonder, computer science is what really gives birth to our ideas. Every single line of code functions like human organs, making a project vivacious and active; this is what fuels my interest and passion for coding.
            <br />
            <br />
            Since I first started coding, I have organically picked up different skills and languages, making my creations possible. At the age of 11, I started coding with a simple game script, which added another layer of joy to the game; today, I immersed myself in these fields not merely for the intellectual thrill, but for their capacity to address real-world challenges. Whether identifying human settlements in satellite imagery, segmenting blood vessels in intricate 3D medical scans, or optimizing large-scale models to run more efficiently, I have seen how AI/ML can reshape our world for the better. I believe in making my work accessible—in the spirit of the open source ethos—so that together, we can push the boundaries of what technology can accomplish.
            <br />
            <br />
            I, as a Software Engineer, believe that it is my mission to listen to people’s inconveniences and strive to improve everyone’s well-being. Whether it’s enhancing educational experiences in the face of new generative AI tools, building more sustainable and scalable machine learning systems, or leveraging advanced neural networks to guide infrastructure development and healthcare decisions, I remain dedicated to utilizing AI for social good. My life-long goal is to provide a service that leads to a thriving and ever-improving standard of living for all members of society.
            <br />
          </p>
        </div>
        <div className='bgBox aboutBox'>
          <div className='bgCover'>
            <video
              className='bgVid center'
              preload='auto'
              autoPlay={true}
              loop='loop'
              muted='muted'
              volume='0'
              webkit-playsinline=""
            >
              <source src='https://i.imgur.com/WnqQ9SU.mp4' type='video/mp4' />
            </video>
          </div>
        </div>
        <div className='pageBody'>
          <h2>Education</h2>
          {this.getEducation(Data['Education'])}
        </div>
      </>
    )
  }
}

export default AboutMe
