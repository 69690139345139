import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as actions from '../../reducers/actions'
import Item from '../../components/controls/ProjectItem'

import GithubMD from '../../assets/md/Github.md';

import ESD from '../../assets/img/project/ESD.png'
import TLC_PLOT from '../../assets/img/project/TLC_PLOT.png'
import SENNET_HOA from '../../assets/img/project/SENNET_HOA.png'
import SnoozeWise from '../../assets/img/project/SnoozeWise.png'
import EMOTION from '../../assets/img/project/EMOTION.png'
import MINEFISH from '../../assets/img/project/MINEFISH.png'
// import HW_STATS from '../../assets/img/project/HW_STATS.png'

import GithubIMG from '../../assets/img/project/Github.jpeg'
import AvonFoodIMG from '../../assets/img/project/AvonFood.png'
import CoronaLiveIMG from '../../assets/img/project/CoronaLive.jpg'
import COVIDDiscordIMG from '../../assets/img/project/COVID-19Discord.png'
import OpenCVIMG from '../../assets/img/project/OpenCV.gif'
import SmartSwitchIMG from '../../assets/img/project/SmartSwitch.png'
import CUBEBotIMG from '../../assets/img/project/CUBE_Bot.png'
import CUBEEndlessIMG from '../../assets/img/project/CUBE_Endless.png'

import CUBE_Bukkit_1 from '../../assets/img/project/Details/CUBE_Bukkit_1.png'
//import CUBE_Bot1 from '../../assets/img/project/Details/CUBE_Bot1.png'
import ColorPicker1 from '../../assets/img/project/Details/ColorPicker1.png'
import EasyFine from '../../assets/img/project/Details/EasyFine.png'
import ImgToText from '../../assets/img/project/Details/ImgToText.png'
import LocationTracker1 from '../../assets/img/project/Details/LocationTracker1.png'
import Lockdown from '../../assets/img/project/Details/Lockdown.png'
import OTP from '../../assets/img/project/Details/OTP.jpg'
import RaspberryPiDiscordBotServer1 from '../../assets/img/project/Details/RaspberryPiDiscordBotServer1.jpg'
import COVID_Mask from '../../assets/img/project/Details/COVID_Mask.jpg'
import Solar1 from '../../assets/img/project/Details/Solar1.jpg'
import SolarF3 from '../../assets/img/project/Details/SolarF3.jpg'
import SoundMixer from '../../assets/img/project/Details/SoundMixer.jpg'
import TensorFlowAPI from '../../assets/img/project/Details/TensorFlowAPI.png'
//import TextReader1 from '../../assets/img/project/Details/TextReader1.png'

//import Paimon from '../../assets/img/testimg.gif'
//import RTE from 'react-typing-effect'

class Projects extends React.Component {
  render() {
    return (
      <>
        <h1>Projects</h1>
        {/*<h4>You can check more detials by cliking each items.</h4>*/}
        <div className='pageBody'>
          <h2>Pinned Projects</h2>
          <div className='projectGrid'>
            <Item image={TLC_PLOT} title='Investigating Autograder Usage in the Post- Pandemic and LLM Era' description='Collaborated with PhD student Jason Lee Weber in the Teaching and Learning in Computing lab to analyze student behaviors across multiple submissions for autograder-based coding assignments. Developed a Python wrapper for the Gradescope API and a multi-threaded scraper to collect all course submissions. Performed data pre-processing to prepare the data for analysis and plotting. Published a poster in the 56th ACM Technical Symposium on Computer Science Education, SIGCSE, 2025.' content={GithubMD} />
            <Item image={SENNET_HOA} title='Hacking the Human Vasculature in 3D' description='Utilized Python, PyTorch, and OpenCV to develop an advanced CNN model with Attention U-Net architecture. Created a machine-learning model that reconstructs 3D vascular structures from scan images of human kidneys. Achieved a significant improvement in segmentation accuracy, with a surface dice coefficient of approximately 0.7.' content={GithubMD} />
            <Item image={ESD} title='Electricity & Settlement Detection in Africa ' description='Developed a fusion model combining ResNet and U-Net architectures to analyze high-resolution satellite imagery. Leveraged Sentinel and VIIRS datasets, achieving 71% accuracy in infrastructure classification. Provided scalable insights for sustainable infrastructure planning in underserved regions.' content={GithubMD} />
            <Item image={SnoozeWise} title='SnoozeWise' description='Developed an iOS app for personalized sleep analysis and management, utilizing Swift and Apple HealthKit. Employed advanced LSTM model to offer personalized sleep feedback and optimal bedtime recommendations. Implemented an intuitive UI/UX in Swift UI, facilitating easy access to sleep data and predictions.' content={GithubMD} />
            <Item image={EMOTION} title='Machine Learning Model for Emotion Recognition' description='Employed Python and PyTorch to develop facial emotion detection models with over 95% accuracy. Implemented and experimented with CNNs, ResNet, and Hyperdimensional Computing models. Enhanced model accuracy through ensemble learning and Bayesian Hyperparameter Optimization.' content={GithubMD} />
            <Item image={MINEFISH} title='MineFish' description='Developed a macro for Minecraft to automate interactions with the game’s fishing mechanics. Integrated OpenCV for precise screen recognition, enabling accurate detection of in-game events and actions. Designed an intuitive user interface for managing settings, enhancing ease of use for non-technical users. Achieved over 6,000 downloads as of November 2024, with positive feedback from users for reliability and efficiency.' content={GithubMD} />
            <Item image={GithubIMG} title='Open Source' description='In the spirit of the open source movement, I share all of my source code with everyone, believing this movement can improve our society. Shared more than 80,000 lines of codes in over 100 GitHub repositories.' content={GithubMD} />
            <Item image={AvonFoodIMG} title='Avon Food' description='A service that allows students to check and rate the menus served at the refectory. Students can provide feedback on the quality of the food. Supports cross-platform (iOS, Android, Web).' content={GithubMD} />
            <Item image={CoronaLiveIMG} title='CoronaLive' description='A website that provides the information and dashboard of the COVID-19 pandemic.' content={GithubMD} />
            <Item image={COVIDDiscordIMG} title='COVID-19 Discord Bot' description='Discord Bot that provides the information and dashboard of COVID-19 pandemic.' content={GithubMD} />
            <Item image={OpenCVIMG} title='Hand gesture drone controlling system' description='Fist and Face detection algorithm with a skin tone filter using Python and OpenCV library.' content={GithubMD} />
            <Item image={SmartSwitchIMG} title='Smart Light Switch' description='A smart room light switch for people who have difficulties with controlling objects with hands. Works with Voice Assistance systems such as Google Assistant and Amazon Alexa.' content={GithubMD} />
            <Item image={CUBEBotIMG} title='CUBE Bot' description='An AI-based Discord chatting bot, currently used in over 3,000 servers by more than 60,000 users.' content={GithubMD} />
            <Item image={CUBEEndlessIMG} title='CUBE Endless' description='A 2D Side-scrolling game. First project with Unity game engine and C# programming language.' content={GithubMD} />
          </div>
          <p>You can find some live streams of the development process from <a href='https://www.youtube.com/playlist?list=PLzl5vfcJONB4aQVurvCxVsq8mhdRIKzx8'>here</a>.</p>
          <br /> <br />
          <h2>Other porjects</h2>
          <div className='projectGrid'>
            <Item image={COVID_Mask} title='COVID Face Shield' description='Face shield to protect nurses and doctors from a droplet infection. Designed to open and close the protective film easily when they have a short break.' content={GithubMD} />
            <Item image={CUBE_Bukkit_1} title='CUBE Bukkit' description='An easy Minecraft server operator and manager. Provide a user-friendly GUI instead of the original console window for those people who have no experience with game server operating.' content={GithubMD} />
            <Item image={ColorPicker1} title='Color Picker' description='Color Picker will let you easily and quickly pick any color from your screen and copy it into the clipboard. You also can save the color that you picked or manually choose for future use.' content={GithubMD} />
            <Item image={EasyFine} title='EasyFine' description='EasyFine is an easy OptiFine installer. You can easily download OptiFine based on the Minecraft version that you are looking for. Support a one-click download and install.' content={GithubMD} />
            <Item image={Lockdown} title='Lockdown' description='Lockdown helps to limit your pc uses. You can limit the amount of time that you would like to spend, or you can lock your applications at a certain time.' content={GithubMD} />
            <Item image={ImgToText} title='Img To Text' description='By using OpenCV, this program detects the characters in the picture and copy them into the form of text.' content={GithubMD} />
            <Item image={LocationTracker1} title='Location Tracker' description="Tracking your own device's location by using GPS module, and send the location data back to the server. Also can display the locations that your device had been by using google map." content={GithubMD} />
            <Item image={OTP} title='OTP' description='The basic One Time Password (OTP) module that syncs with PC and Mobile devices (that uses Xamarin) without an internet connection. ' content={GithubMD} />
            <Item image={RaspberryPiDiscordBotServer1} title='Raspberry Pi Micro Server' description='Microserver that operates backend server, discord bots, and Network-attached storage (NAS). Learned how to manage and operate the Linux based OS and server.' content={GithubMD} />
            <Item image={Solar1} title='Solar Generator' description='Solar generator with 4 solar panels connected in parallel and series.' content={GithubMD} />
            <Item image={SolarF3} title='Sunflower Solar Generator' description='A solar generator that follows the direction of the sun to maximize the time and area of the light source that the solar generator can obtain.' content={GithubMD} />
            <Item image={SoundMixer} title='Sound Mixer' description='A virtual sounds manager and mixer for Windows 10.' content={GithubMD} />
            <Item image={TensorFlowAPI} title='Tensor Flow API' description='Object detector by using Tensorflow API. Also made a device that detects the object and notice visually impaired person which objects are in the camera vision.' content={GithubMD} />
            {/*<Item image={Paimon} title='Paimon' description='Hehe Te Nandayo?!' content={GithubMD} />*/}
          </div>
          <p>I am still updating lists, and also working on new projects...</p>
          <p>You can also find some interesting projects on my <a href='https://github.com/Nitro1231'>GitHub</a>.</p>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  pj: state.project
})
export default compose(connect(mapStateToProps, actions))(Projects)